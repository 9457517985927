#vocation-form {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
}

.form-control {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
}

.form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

#vocation-btn {
  font-family: "Poppins", sans-serif;
}

#vocation-btn:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.form-label {
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
}

.err-danger {
  color: red;
  font-weight: 600;
}

.text-danger {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
}
