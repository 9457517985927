.single-team {
  box-shadow: 0px 7px 7px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-bottom: 10px;
  margin-left: 10px;
  font-family: Poppins, sans-serif;
  height: 400px;
}
.single-team:hover .social {
  cursor: pointer;
  opacity: 1;
  transform: rotateY(0deg) scale(1, 1);
}
.img-area {
  overflow: hidden;
  position: relative;
  height: 300px;
  object-fit: cover;
}
.img-area img {
  width: 100%;
}
.img-area:hover img {
  transform: scale(1.2);
}
.img-area img {
  transition: all 0.4s ease 0s;
}
@media (max-width: 768px) {
  .img-area img {
    display: inline-block;
  }
}
.img-area .social {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: 0.5s;
  transform: rotateY(180deg) scale(0.5, 0.5);
}
.img-area .social ul {
  text-align: center;
  position: relative;
  top: 175px;
}
.img-area .social ul li a {
  border: 1px solid #fff;
  color: #fff;
  display: block;
  font-size: 20px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
}
.img-area .social ul li a:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid transparent;
}
.img-text {
  padding: 25px;
  color: black;
  text-align: center;
  font-family: Poppins, sans-serif !important;
}
.img-text h4 {
  margin: 0 0 5px;
  font-size: 19px;
}
.img-text h5 {
  font-size: 16px;
  color: #545154;
}

/* new style */

.nav-tabs {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between; /* Distribute tabs evenly */
  align-items: center; /* Align items vertically */
}

.nav-tabs .nav-item {
  margin-bottom: 10px;
}

.nav-tabs .nav-item .nav-link {
  font-size: 18px;
  color: #555;
  border: none;
  background: transparent;
  border-bottom: 2px solid transparent;
  padding: 10px 20px;
  transition: all 0.3s ease;
  border-radius: 8px; /* Add rounded corners */
}

.nav-tabs .nav-item .nav-link.active {
  border-color: #ff6f61;
  color: #ff6f61;
  font-weight: bold;
}

.nav-tabs .nav-item .nav-link:hover {
  color: #ff6f61;
  text-decoration: none;
  background-color: #f0f0f0; /* Add hover background color */
}

.nav-tabs .nav-item:first-child .nav-link {
  border-top-left-radius: 8px; /* Add rounded corners to the first tab */
  border-bottom-left-radius: 8px;
}

.nav-tabs .nav-item:last-child .nav-link {
  border-top-right-radius: 8px; /* Add rounded corners to the last tab */
  border-bottom-right-radius: 8px;
}

.nav-tabs .nav-link:focus {
  outline: none; /* Remove outline on focus */
}

.nav-tabs .nav-link.disabled {
  cursor: not-allowed; /* Change cursor for disabled tab */
  color: #ccc; /* Change color for disabled tab */
}

.nav-tabs .nav-link.disabled:hover {
  background-color: transparent; /* Remove hover effect for disabled tab */
}

.nav-tabs .nav-link.disabled.active {
  border-color: #ccc; /* Change border color for disabled active tab */
  color: #ccc; /* Change color for disabled active tab */
}
